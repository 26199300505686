import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: [],
    roles: [],
    races: [],
    signs: [],
    signTypes: [],
    tenants: [],
    organizations: [],
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getRoles(state) {
      return state.roles
    },
    getRaces(state) {
      return state.races
    },
    getSigns(state) {
      return state.signs
    },
    getSignTypes(state) {
      return state.signTypes
    },
    getTenants(state) {
      return state.tenants
    },
    getOrganizations(state) {
      return state.organizations
    },
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_RACES(state, races) {
      state.races = races
    },
    SET_SIGNS(state, signs) {
      state.signs = signs
    },
    SET_SIGNTYPES(state, signTypes) {
      state.signTypes = signTypes
    },
    SET_TENANTS(state, tenants) {
      state.tenants = tenants
    },
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
  },
  actions: {
    async fetchUsers() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get("https://app.locusbase.no:4001/api/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.commit('SET_USERS', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRoles() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get(
          "https://app.locusbase.no:4001/api/roles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.commit('SET_ROLES', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRaces() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get("https://app.locusbase.no:4001/api/races", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.commit('SET_RACES', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSigns() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get("https://app.locusbase.no:4001/api/signs?page_size=1000&page=1", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.commit('SET_SIGNS', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSigntypes() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get("https://app.locusbase.no:4001/api/signtypes", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.commit('SET_SIGNTYPES', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTenants() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get(
          "https://app.locusbase.no:4001/api/tenants",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.commit('SET_TENANTS', data)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchOrganizations() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get(
          "https://app.locusbase.no:4001/api/organizations",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.commit('SET_ORGANIZATIONS', data)
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
  }
})
