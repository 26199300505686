<template>
  <div class="hamburger-menu">
    <div class="hamburger-container">
      <p @click="Home()">Home</p>
      <p @click="Adminpanel()">Admin panel</p>
      <!-- <p @click="Test()">API test</p> -->
      <p @click="Logout()">Logout</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
  methods: {
    Home() {
      this.$router.push("/");
      this.CloseBurger();
    },
    Adminpanel() {
      this.$router.push("/adminpanel");
      this.CloseBurger();
    },
    Test() {
      this.$router.push("/externalapi");
      this.CloseBurger();
    },
    Logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
      this.CloseBurger();
    },
    CloseBurger() {
      this.$emit("close-burger");
    },
  },
};
</script>

<style scoped>
p {
  margin: 10px 0;
  cursor: pointer;
}
.hamburger-container {
  padding: 15px;
}

.hamburger-menu {
  position: absolute;
  right: 15px;
  width: 300px;
  background-color: #0a1720;
  border: 2px solid #ffffff;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .hamburger-menu {
    width: 100%;
    text-align: center;
    right: 0;
  }
}
</style>