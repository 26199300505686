<template>
  <div>
    <button @click="callRaces">Call races</button>
    <button @click="callUsers">Call users</button>
    <button @click="callUsersRoles">Call users roles</button>

    <button @click="callSigns">Call signs</button>
    <button @click="callSigntypes">Call signtypes</button>
    <button @click="callTenants">Call tenants</button>
    <button @click="callOrganizations">Call organizations</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "external-api",
  methods: {
    async callRaces() {
      const token = localStorage.token;
      const { data } = await axios.get("https://app.locusbase.no:4001/api/races", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
    },
    async callUsers() {
      const token = localStorage.token;
      const { data } = await axios.get("https://app.locusbase.no:4001/api/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
    },
    async callUsersRoles() {
      const token = localStorage.token;
      const { data } = await axios.get(
        "https://app.locusbase.no:4001/api/roles",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
    },
    async callSigns() {
      const token = localStorage.token;
      const { data } = await axios.get("https://app.locusbase.no:4001/api/signs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
    },
    async callSigntypes() {
      const token = localStorage.token;
      const { data } = await axios.get("https://app.locusbase.no:4001/api/signtypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
    },
    async callTenants() {
      const token = localStorage.token;
      const { data } = await axios.get(
        "https://app.locusbase.no:4001/api/tenants",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
    },
    async callOrganizations() {
      const token = localStorage.token;
      const { data } = await axios.get(
        "https://app.locusbase.no:4001/api/organizations",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
    },
  },
};
</script>