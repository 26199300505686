<template>
  <div class="create-sign">
    <div class="create-sign-container">
      <div class="new-line">Name: <input type="text" v-model="name" /></div>
      <div class="new-line">
        Sign:
        <select name="" id="" v-model="type">
          <option v-for="(t, i) in types" :key="i" :value="t.id">
            {{ t.name }}
          </option>
          <!-- <option value="85dff9b7-2851-488e-8dc1-469c090bb25c">No entry</option> -->
          <!-- <option value="85dff9b7-2851-488e-8dc1-469c090bb25c">No entry</option> -->
        </select>
      </div>
      <div class="new-line">Selected: {{ type }}</div>
      <div class="new-line">Notes: <textarea v-model="notes" /></div>
      <div class="new-line">
        <button class="btn-gradient" @click="createSign">Create sign</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "CreateSign",
  data() {
    return {
      name: "1",
      type: "85dff9b7-2851-488e-8dc1-469c090bb25c",
      types: [
        { id: "85dff9b7-2851-488e-8dc1-469c090bb25c", name: "302" },
        { id: "7ee1fe29-3e68-4640-8788-aedac3665955", name: "SafeCycling" },
        { id: "920794a0-f089-4d5e-a5b0-6da9aa98b493", name: "Info sign" },
      ],
      notes: "",
    };
  },
  mounted() {
    console.log(this.$auth.user);
  },
  methods: {
    async createSign() {
      const token = localStorage.token;
      let json = {
        name: this.name,
        signtype_id: this.type,
        notes: this.notes,
        race_day: 1,
        sign_type: {
          id: this.type,
        },
      };
      console.log(json);
      await axios
        .post(`https://app.locusbase.no:4001/api/signs`, json, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.getSigns();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSigns() {
      await store.dispatch("fetchSigns");
      if (this.type == "85dff9b7-2851-488e-8dc1-469c090bb25c") {
        this.$emit("change-category", 5);
      }
      if (this.type == "7ee1fe29-3e68-4640-8788-aedac3665955") {
        this.$emit("change-category", 6);
      }
      if (this.type == "920794a0-f089-4d5e-a5b0-6da9aa98b493") {
        this.$emit("change-category", 7);
      }
    },
  },
};
</script>

<style scoped>
.create-sign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-line {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0 0 0;
}

.btn-gradient {
  padding: 10px;
}

textarea {
  width: 270px;
  height: 100px;
}
@media only screen and (max-width: 600px) {
  .create-sign {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>