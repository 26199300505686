import { render, staticRenderFns } from "./ChooseTrafficSign.vue?vue&type=template&id=4d2a1bff&scoped=true&"
import script from "./ChooseTrafficSign.vue?vue&type=script&lang=js&"
export * from "./ChooseTrafficSign.vue?vue&type=script&lang=js&"
import style0 from "./ChooseTrafficSign.vue?vue&type=style&index=0&id=4d2a1bff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d2a1bff",
  null
  
)

export default component.exports