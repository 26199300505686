<template>
  <div class="nav-sub-category">
    <div class="nav-row">
      <div
        class="sub-category-wrapper"
        v-for="(sub, index) in subCategories"
        :key="index"
      >
        <h5 class="sub-category" @click="selectCategory(sub.id, $event)">
          {{ sub.name }}
        </h5>
      </div>
    </div>
    <div class="nav-sub-category-container">
      <img class="custom-dashed-line" :src="mySVG" alt="dashed line" />
      <RideList class="ridelist" v-if="selectedSubcategory == 0" />
      <RideList class="ridelist" v-if="selectedSubcategory == 1" />
      <CreateRace
        v-if="selectedSubcategory == 2"
        @change-category="changeCategory"
      />
      <UserList v-if="selectedSubcategory == 3" :users="users" />
      <CreateUser v-if="selectedSubcategory == 4" />
      <SignCollection v-if="selectedSubcategory == 5" :signs="sign302" />
      <SignCollection
        v-if="selectedSubcategory == 6"
        :signs="signSafeCycling"
      />
      <SignCollection v-if="selectedSubcategory == 7" :signs="signInfo" />
      <SignCollection v-if="selectedSubcategory == 8" :signs="signArchive" />
      <CreateSign
        v-if="selectedSubcategory == 9"
        @change-category="changeCategory"
      />
    </div>
  </div>
</template>

<script>
import CreateUser from "@/components/administrativ/create/CreateUser.vue";
import CreateRace from "@/components/administrativ/create/CreateRace.vue";
import CreateSign from "@/components/administrativ/create/CreateSign.vue";
import RideList from "@/components/management/RideList.vue";
import UserList from "@/components/management/UserList.vue";
import SignCollection from "@/components/administrativ/signs/SignCollection.vue";
import store from "@/store/index.js";

export default {
  name: "navSubCategory",
  props: {
    subCategories: Array,
    resetSubcategory: Number,
  },
  components: {
    RideList,
    CreateRace,
    UserList,
    CreateUser,
    SignCollection,
    CreateSign,
  },
  data() {
    return {
      selectedSubcategory: 0,
      signs: [],
      sign302: [],
      signSafeCycling: [],
      signInfo: [],
      signArchive: [],
      users: {},
      mySVG: require("@/assets/images/styles/custom-dashed-line.svg"),
    };
  },
  async mounted() {
    await this.fetchUsers();
    await this.fetchSigns();
    this.call302Signs();
    this.callSafeCyclingSigns();
    this.callArchivedSigns();
    this.callInfoSigns();
    await setInterval(() => {
      this.fetchSigns();
      this.call302Signs();
      this.callSafeCyclingSigns();
      this.callArchivedSigns();
      this.callInfoSigns();
    }, 10000);
  },
  methods: {
    changeCategory(cat) {
      this.selectedSubcategory = cat;
    },
    selectCategory(id, e) {
      try {
        this.selectedSubcategory = id;
        let headers = document.getElementsByTagName("h5");
        for (let header = 0; header < headers.length; header++) {
          headers[header].style.color = "#000000";
        }
        if (e) {
          e.target.style.color = "#ffbb00";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSigns() {
      await store.dispatch("fetchSigns");
      this.signs = store.state.signs;
    },
    async call302Signs() {
      this.sign302 = [];
      for (let i = 0; i < this.signs.length; i++) {
        if (
          this.signs[i].signtype_id == "85dff9b7-2851-488e-8dc1-469c090bb25c"
        ) {
          this.sign302.push(this.signs[i]);
        }
      }
    },
    async callSafeCyclingSigns() {
      this.signSafeCycling = [];
      for (let i = 0; i < this.signs.length; i++) {
        if (
          this.signs[i].signtype_id == "7ee1fe29-3e68-4640-8788-aedac3665955"
        ) {
          this.signSafeCycling.push(this.signs[i]);
        }
      }
    },
    async callInfoSigns() {
      this.signInfo = [];
      for (let i = 0; i < this.signs.length; i++) {
        if (
          this.signs[i].signtype_id == "920794a0-f089-4d5e-a5b0-6da9aa98b493"
        ) {
          this.signInfo.push(this.signs[i]);
        }
      }
    },
    async callArchivedSigns() {
      this.signArchive = [];
      for (let i = 0; i < this.signs.length; i++) {
        if (this.signs[i].state == 3) {
          this.signArchive.push(this.signs[i]);
        }
      }
    },

    async fetchUsers() {
      await store.dispatch("fetchUsers");
      this.users = store.state.users;
    },
  },
};
</script>

<style scoped>
.nav-sub-category {
  width: 100%;
}

.nav-row {
  display: flex;
  flex-direction: row;
}

.sub-category-wrapper:first-child {
  color: #ffbb00;
}

.sub-category {
  margin-right: 20px;
  cursor: pointer;
}
.custom-dashed-line {
  width: 100%;
  padding-bottom: 30px;
  padding-top: 20px;
}
/* .nav-sub-category-container {
} */

@media only screen and (max-width: 600px) {
  .nav-sub-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-sub-category-container {
    display: flex;
    flex-direction: column;
  }
  .ridelist {
    display: flex;
  }
}
</style>