<template>
  <div id="app">
    <Navbar v-if="$router.currentRoute.path != '/map'" />
    <!-- <Category :categories="categories" v-if="$router.currentRoute.path == '/adminpanel'"/> -->
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Category from "./components/administrativ/Category";
export default {
  data() {
    return {
      categories: [
        {
          mainCategory: "Ritt",
          subCategories: [
            { name: "Aktive", id: 0 },
            { name: "Tidligere løp", id: 1 },
            { name: "Nytt løp", id: 2 },
          ],
        },
        {
          mainCategory: "Brukere",
          subCategories: [
            { name: "Oversikt", id: 3 },
            { name: "Opprett ny", id: 4 },
          ],
        },
        {
          mainCategory: "Skilt",
          subCategories: [
            { name: "302", id: 5 },
            { name: "SafeCycling", id: 6 },
            { name: "Arkiv", id: 7 },
            { name: "Nytt skilt", id: 8 },
          ],
        },
      ],
    };
  },
  components: {
    Navbar,
    Category,
  },
  methods: {
    isMobile() {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      )
      }
  },
};
</script>


<style>
#app {
  margin: 0;
  min-height: 100%;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #0a1720;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.btn-gradient {
  border-radius: 5px;
  border: none;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(242, 200, 75, 1) 0%,
    rgba(242, 96, 12, 1) 20%,
    rgba(242, 96, 12, 1) 100%
  );
}
.textalign-center {
  text-align: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
}
</style>
