<template>
  <div class="adminpanel-container">
    <NavCategory class="category-list" :categories="categories" />
  </div>
</template>

<script>
import NavCategory from "@/components/administrativ/Category.vue";
export default {
  name: "Adminpanel",
  data() {
    return {
      categories: [
        {
          mainCategory: "Race",
          subCategories: [
            { name: "Active", id: 0 },
            { name: "Previous races", id: 1 },
            { name: "New race", id: 2 },
          ],
        },
        {
          mainCategory: "Users",
          subCategories: [
            { name: "Overview", id: 3 },
            { name: "New user", id: 4 },
          ],
        },
        {
          mainCategory: "Signs",
          subCategories: [
            { name: "302", id: 5 },
            { name: "SafeCycling", id: 6 },
            { name: "Info", id: 7 },
            { name: "Archive", id: 8 },
            { name: "New sign", id: 9 },
          ],
        },
      ],
    };
  },
  components: {
    NavCategory,
  },
  methods: {
    toggleDarkMode() {
      let page = document.getElementsByClassName("adminpanel-container")[0];
      page.style.backgroundColor == "rgb(10, 23, 32)"
        ? (page.style.backgroundColor = "rgb(255, 255, 255)")
        : (page.style.backgroundColor = "rgb(10, 23, 32)");
      page.style.color == "rgb(255, 255, 255)"
        ? (page.style.color = "rgb(0, 0, 0)")
        : (page.style.color = "rgb(255, 255, 255)");
    },
  },
};
</script>

<style scoped>
.adminpanel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  width: 100%;
  padding-top: 60px;
  min-height: 93vh;
}
.ridelist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .adminpanel-container {
  padding-top: 30px;
 
  }
 .category-list {
   width: 100%;
 }
}
</style>