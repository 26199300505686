<template>
  <div class="home">
    <!-- MIDLERTIDIG -->
    <div class="centered">
      <img :src="$auth.user.picture" alt="" />
      <h1>{{ $auth.user.name }}</h1>
      <h1>{{ $auth.user.email }}</h1>
      <h1>Authenticated: {{ $auth.isAuthenticated }}</h1>
    </div>
    <!-- MIDLERTIDIG -->

    <div class="link-button-container">
      <div class="link-btn" v-for="(button, index) in buttons" :key="index">
        <LinkButton :text="button.text" :link="button.link" />
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/common/LinkButton.vue";

export default {
  name: "Home",

  data() {
    return {
      buttons: [
        // {text: "Registrere", link: "/"},
        // {text: "Fjerne", link: "/"},
        { text: "Admin panel", link: "/adminpanel" },
      ],
      users: [],
    };
  },
  components: {
    LinkButton,
  },
  mounted() {
    // console.log(store.state.users);
    this.setToken();
    console.log(localStorage.token);
  },
  methods: {
    login() {
      this.$auth.loginWithPopup();
    },
    logout() {
      this.$auth.logout();
    },
    async setToken() {
      const token = await this.$auth.getTokenSilently();
      localStorage.setItem("token", token);
    },
  },
};
</script>

<style scoped>
.centered {
  text-align: center;
}

.link-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.link-btn {
  width: 20%;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .link-btn {
    width: 95%;
  }
}
</style>