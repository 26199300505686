<template>
  <div class="selected-ride">
    <div class="race-info">
      <h2>{{ $route.query.name }}</h2>
      <!-- <h2>{{ $route.query.distance }}km</h2> -->
      <img class="company-logo" :src="logo" alt="Bilde av kompani, bedrift" />
      <h2 v-if="$route.query.scheduled_at">
        <span>
          {{ new Date($route.query.scheduled_at).getDate() }}/{{
            new Date($route.query.scheduled_at).getMonth() + 1
          }}/{{ new Date($route.query.scheduled_at).getFullYear() }}
        </span>
      </h2>
    </div>
    <div class="map-wrapper">
      <MiniMap />
    </div>
    <div class="button-wrapper">
      <LinkButton text="View map" link="/map" :queries="$route.query" />
    </div>
    <div class="guard-list">
      <div class="vertical-list">
        <h3>Guards</h3>
        <p>navn</p>
        <p>navn</p>
        <p>navn</p>
        <p>navn</p>
      </div>
      <div class="vertical-list">
        <h3>Telephone</h3>
        <p>tlf</p>
        <p>tlf</p>
        <p>tlf</p>
        <p>tlf</p>
      </div>
      <div class="vertical-list"></div>
    </div>
    <div class="sign-list">
      <h3>Signs</h3>
      <div class="sign-wrapper">
        <p>Bilde</p>
        <p>Bilde</p>
        <p>Bilde</p>
        <p>Bilde</p>
      </div>
    </div>
    <button class="btn-gradient btn-size" @click="deleteRace()">Delete race</button>
  </div>
</template>

<script>
import axios from "axios";
import MiniMap from "@/components/Minimap.vue";
import LinkButton from "@/components/common/LinkButton.vue";
export default {
  name: "selectedRide",
  components: {
    MiniMap,
    LinkButton,
  },
  data() {
    return {
      images: [
        {
          id: "9e6af333-68df-4328-afd2-83f234b0aeed",
          img: require("@/assets/images/logos/vink-logo.png"),
        },
        {
          id: "50837eca-3ef5-456f-8799-580c4a4a10fc",
          img: require("@/assets/images/logos/locus-base-white-logo.svg"),
        },
      ],
      logo: null,
    };
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    async deleteRace() {
      if (confirm("Fjerne løpet?")) {
        const token = localStorage.token;
        await axios.delete(
          `https://app.locusbase.no:4001/api/races/${this.$route.query.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.$router.go(-1);
      }
    },
    getOrganization() {
      var ele = this.images.find(
        (x) => x.id == this.$route.query.organization_id
      );
      this.logo = ele.img;
    },
  },
};
</script>

<style scoped>
.selected-ride {
  margin: auto;
  width: 900px;
}

.selected-ride div {
  padding: 15px 0;
}

.race-info {
  text-align: center;
}

.company-logo {
  width: 200px;
}

.map-wrapper {
  margin: auto;
  height: 500px;
  width: 100%;
}

.guard-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vertical-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sign-list {
  display: flex;
  flex-direction: column;
}

.sign-wrapper {
  display: flex;
  flex-direction: row;
}
.btn-size {
  padding: 15px 40px;
}
@media only screen and (max-width: 600px) {
  .selected-ride {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>