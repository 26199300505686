<template>
  <div class="navbar-map">
    <div class="navbar-container">
      <b-navbar toggleable>
        <!-- Left Aligned -->
        <b-navbar-brand>
          <img
            class="logo-size"
            src="@/assets/images/logos/locus-base-white-logo.svg"
            alt="logo"
          />
        </b-navbar-brand>
        <!-- Right Aligned -->
        <img
          class="hamburger-size"
          src="@/assets/images/icons/hamburger-gradient.svg"
          alt="menu"
          @click="flipBurger()"
        />
        <MapHamburgerMenu v-if="this.burger" @close-burger="flipBurger()" />
      </b-navbar>
    </div>
  </div>
</template>

<script>
import MapHamburgerMenu from "@/components/map/MapHamburgerMenu.vue";
export default {
  Name: "NavbarMap",
  data() {
    return {
      burger: false,
    };
  },
  components: {
    MapHamburgerMenu,
  },
  methods: {
    flipBurger() {
      this.burger = !this.burger;
    },
  },
};
</script>

<style scoped>
.navbar-map {
  margin: 0 auto;
  width: 90%;
  z-index: 1;
  padding: 10px 40px;
  border-radius: 25px;
  background-color: #0a1720;
}
.logo-size {
  width: 75px;
  
}
.hamburger-size {
  width: 35px;
}

@media only screen and (max-width: 600px) {
  .navbar-map {
    width: 100%;
  }
  .logo-size {
    width: 45%;
  }
  .hamburger-size {
    width: 10%;
  }
}
</style>