<template>
  <div class="sign-confirm">
      <div class="signpositionconfirm-container textalign-center">
          <h3>Confirm position</h3>
          <p>Sign number 01</p>
          <button class="btn-gradient btn-size">Register</button>
      </div>
  </div>
</template>

<script>
export default {
name: 'SignPositionConfirm',
}
</script>

<style scoped>
.signpositionconfirm-container {
  font-size: 18px;
  overflow: hidden;
  padding: 20px;
  border: 2px dashed yellow;
  border-radius: 10px;
}

.btn-size {
 width: 250px;
 height: 45px;
}
</style>