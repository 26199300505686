<template>
  <div class="signinfo-container">
    <div class="singinfo-box">
      <div class="signinfo">
        <h4 class="textalign-center">Sign information</h4>
        <div class="text-spacing" v-for="(sign, index) in signs" :key="index">
          Sign number {{ sign.sign }}
        </div>
        <div class="text-spacing" @click="currentState">{{ isActive? "Koble til" : "Koble fra" }}</div>
        <div class="text-spacing">GPS coordinates</div>
      </div>
      <div class="textalign-center">
        <span>Comment</span>
        <b-form-textarea
          id="textarea"
          v-model="comment"
          rows="6"
          max-rows="6"
          no-resize
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInfo",
  data() {
    return {
      signs: [
        {
          id: 0,
          sign: "02",
        },
      ],
      isActive: false,
     comment: '',
    };
  },
  methods: {
    currentState() {
     this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
.signinfo-container {
  display: flex;
}
.singinfo-box {
  font-size: 18px;
  overflow: hidden;
  padding: 20px;
  border: 2px dashed yellow;
  border-radius: 10px;
}
.signinfo {
  padding-bottom: 40px;
}
.textalign-center {
  text-align: center;
}
.text-spacing {
  padding-bottom: 15px;
}
</style>
