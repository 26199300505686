<template>
  <div class="g-map-container">
    <div class="g-map">
      <GmapMap
        :options="mapOptions"
        :center="center"
        :zoom="zoomLevel"
        map-type-id="terrain"
        class="g-map-control"
      >
        <div :key="index" v-for="(s, index) in signs">
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == '302'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="stopSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == '302'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="stopSignActiveOptions"
          />
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == 'SafeCycling'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="safecyclingSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == 'SafeCycling'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="safecyclingSignActiveOptions"
          />
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == '560'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="infoSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == '560'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(s)"
            :icon="infoSignActiveOptions"
          />
        </div>
        <div v-for="(w, index) in waypoints" :key="'W' + index">
          <GmapMarker
            class="placed-waypoint"
            :position="{ lat: w.location.latitude, lng: w.location.longitude }"
            :clickable="true"
            :draggable="false"
            @click="removeWaypoint(w)"
            :icon="waypointOptions"
          />
        </div>
        <gmap-info-window
          :options="{
            maxWidth: 300,
            pixelOffset: { width: 0, height: -35 },
          }"
          :position="infoWindow.location"
          :opened="infoWindow.open"
          @closeclick="infoWindow.open = false"
        >
          <div class="info-window-text">
            <h4>Sign information</h4>
            <div v-if="infoWindow.open">
              <h4>{{ infoWindow.sign.name }}</h4>
              <h4>lat: {{ infoWindow.sign.location.latitude.toFixed(5) }}</h4>
              <h4>lng: {{ infoWindow.sign.location.longitude.toFixed(5) }}</h4>
              <button
                class="google-maps-redirect"
                @click="redirectWithGoogle(infoWindow.sign.location)"
              >
                Get google maps directions
              </button>
            </div>
          </div>
        </gmap-info-window>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import MapNav from "@/components/map/Navbar.vue";
import MapSigns from "@/components/map/ChooseTrafficSign.vue";
import MapPosition from "@/components/map/ConfirmPosition.vue";
import axios from "axios";
export default {
  name: "MapContainer",
  components: {
    MapNav,
    Map,
    MapSigns,
    MapPosition,
  },
  data() {
    return {
      counterInterval: null,
      zoomLevel: 7,
      showCamera: false,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        sign: null,
      },
      center: { lat: 60, lng: 10 },
      signs: [],
      waypoints: [],
      stopSignInactiveOptions: {
        url: require("@/assets/images/signs/gray_302.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      stopSignActiveOptions: {
        url: require("@/assets/images/signs/red_302.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      safecyclingSignInactiveOptions: {
        url: require("@/assets/images/signs/safecycling.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      safecyclingSignActiveOptions: {
        url: require("@/assets/images/signs/safecycling.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      infoSignInactiveOptions: {
        url: require("@/assets/images/signs/clown_zone.jpg"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      infoSignActiveOptions: {
        url: require("@/assets/images/signs/clown_zone.jpg"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      waypointOptions: {
        url: require("@/assets/images/signs/waypoint.png"),
        size: { width: 80, height: 80, f: "px", b: "px" },
        scaledSize: { width: 80, height: 80, f: "px", b: "px" },
      },
      mapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: false,
      },
    };
  },
  destroyed() {
    clearInterval(this.counterInterval);
  },
  mounted() {
    this.callSignsByRace();
    this.counterInterval = window.setInterval(
      function () {
        this.callSignsByRace();
      }.bind(this),
      10000
    );
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      },
      (error) => {
        console.log(error.message);
      }
    );
  },
  methods: {
    async callSignsByRace() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/signs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.signs = data;
    },
    openInfoWindowTemplate(sign) {
      this.zoomLevel = 15;
      this.center = {
        lat: sign.location.latitude,
        lng: sign.location.longitude,
      };
      this.infoWindow.location = {
        lat: sign.location.latitude,
        lng: sign.location.longitude,
      };
      this.infoWindow.sign = sign;
      this.infoWindow.open = true;
    },
    onDecode(url) {
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.g-map-container {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  height: 100%;
  width: 100%;
}

.g-map {
  height: 100%;
  width: 100%;
}
.g-map-control {
  height: 100%;
  width: 100%;
}
.info-window-text {
  color: #000;
  /* background-color: blue; */
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background-color: red;
  color: blueviolet;
}
.google-maps-redirect {
  padding: 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .link-btn {
    width: 95%;
  }
}
</style>