<template>
  <div class="confirm-position">
    <div class="box">
      <b-row class="my-4">
        <h1>Confirm posititon</h1>
      </b-row>
      <b-row class="my-4">
        <h3>Sign number {{ trafficSignNumber[0] }}</h3>
      </b-row>
      <button class="fancy-button" @click="Register()">Register</button>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPosition",
  data() {
    return {
      trafficSignNumber: [1, 2, 3],
    };
  },
};
</script>

<style scoped>
.confirm-position {
  position: absolute;
  right: 5%;
  bottom: 5%;
  left: 5%;
  margin: 30px 1%;
  /* border: 3px solid yellow;*/
  border-radius: 50px; 
  background-color: #0a1720;
  text-align: center;
}

.box {
  padding: 30px;
  border: 4px solid transparent;
  border-image: 3
    repeating-linear-gradient(
      -45deg,
      #ffff00 0,
      #ffff00 3em,
      #000000 0,
      #000000 6em
    );
  border-radius: 50px;
}

.fancy-button {
  width: 75%;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, #ffff00, #ff5500 50%);
  font-size: 3em;
  color: #fff;
}
</style>