<template>
  <div class="sign-wrapper">
    <div class="sign-container">
      <div class="spacer">
        <p>
          Choose day:
          <select name="" id="" @change="changeDay($event)" v-model="raceDay">
            <option v-for="(day, index) in raceDays" :key="index" :value="day">
              {{ day }}
            </option>
          </select>
        </p>
        <div class="image">
          <img
            v-if="
              sign.state == 1 &&
              sign.signtype_id == '85dff9b7-2851-488e-8dc1-469c090bb25c'
            "
            class="sign-display"
            src="@/assets/images/signs/red_302.png"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
            style="filter: grayscale(1)"
          />
          <img
            v-else-if="
              sign.state == 2 &&
              sign.signtype_id == '85dff9b7-2851-488e-8dc1-469c090bb25c'
            "
            class="sign-display"
            src="@/assets/images/signs/red_302.png"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
          />

          <img
            v-if="
              sign.state == 1 &&
              sign.signtype_id == '7ee1fe29-3e68-4640-8788-aedac3665955'
            "
            class="sign-display"
            src="@/assets/images/signs/safecycling.png"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
            style="filter: grayscale(1)"
          />
          <img
            v-else-if="
              sign.state == 2 &&
              sign.signtype_id == '7ee1fe29-3e68-4640-8788-aedac3665955'
            "
            class="sign-display"
            src="@/assets/images/signs/safecycling.png"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
          />
          <img
            v-if="
              sign.state == 1 &&
              sign.signtype_id == '920794a0-f089-4d5e-a5b0-6da9aa98b493'
            "
            class="sign-display"
            src="@/assets/images/signs/clown_zone.jpg"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
            style="filter: grayscale(1)"
          />
          <img
            v-else-if="
              sign.state == 2 &&
              sign.signtype_id == '920794a0-f089-4d5e-a5b0-6da9aa98b493'
            "
            class="sign-display"
            src="@/assets/images/signs/clown_zone.jpg"
            :alt="'Bilde av skilt-type ' + sign.sign_type.name"
          />
        </div>
        <h3>Name: {{ sign.name }}</h3>
        <h4>Type: {{ sign.sign_type.name }}</h4>
        <p>ID: {{ sign.id }}</p>
        <p>Race day: {{ sign.race_day }}</p>
        <p>
          Active: <span v-if="sign.state == 1">No</span
          ><span v-else-if="sign.state == 2">Yes</span
          ><span v-else>Missing</span>
        </p>
        <div v-if="sign.location">
          <button
            class="google-maps-redirect"
            v-if="sign.location.lat != 0 && sign.location.lng != 0"
            @click="redirectWithGoogle(sign.location)"
          >
            Get google maps directions
          </button>
        </div>
        <p>
          QR code:
          <a
            :href="'https://app.locusbase.no/skilt/' + sign.qr_code"
            target="_blank"
            >https://app.locusbase.no/skilt/{{ sign.qr_code }}</a
          >
        </p>
        <button
          class="active-button activate"
          v-if="sign.state == 1"
          @click="updateSign(sign, 2)"
        >
          Activate sign
        </button>
        <button
          class="active-button deactivate"
          v-if="sign.state == 2"
          @click="updateSign(sign, 1)"
        >
          Deactivate sign
        </button>
        <pre v-if="sign.sign_type.name == 560">
          {{ sign.notes }}
        </pre>
        <!-- <button class="delete-button" @click="deleteSign(sign)">
          Slett skilt (ALDRI TRYKK)
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "Sign",
  data() {
    return {
      sign: null,
      raceDay: 1,
      raceDays: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  mounted() {
    this.getInitDay();
    console.log(this.sign);
  },
  methods: {
    changeDay(e) {
      this.raceDay = e.target.value;
      this.GetSign();
    },
    async getInitDay() {
      this.raceDay = await this.getRaceDay();
      console.log(this.raceDay);
      await this.GetSign();
    },
    async getRaceDay() {
      const token = localStorage.token;
      try {
        const { data } = await axios.get(
          `https://app.locusbase.no:4001/api/races?scheduled_at=${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data[0].race_day;
      } catch (error) {
        console.log(error);
        return 1;
      }
    },
    redirectWithGoogle(newPosition) {
      navigator.geolocation.getCurrentPosition((position) => {
        let currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(currentPosition.lat.toFixed(7));
        console.log(currentPosition.lng.toFixed(7));
        console.log(newPosition.latitude.toFixed(7));
        console.log(newPosition.longitude.toFixed(7));
        window.location.href = `https://www.google.com/maps/dir/${currentPosition.lat.toFixed(
          7
        )},${currentPosition.lng.toFixed(7)}/${newPosition.latitude.toFixed(
          7
        )},${newPosition.longitude.toFixed(7)}`;
      });
    },
    async GetSign() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/signs/${this.raceDay}|${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.sign = data;
    },
    async updateSign(sign, state) {
      console.log(sign);
      let activeSign = "";
      if (state == 1) {
        activeSign = "Deaktiver";
      } else if (state == 2) {
        activeSign = "Aktiver";
      }
      if (confirm(activeSign + " skiltet?")) {
        const token = localStorage.token;
        let json = {
          signtype_id: sign.signtype_id,
          state: state,
          race_day: sign.race_day,
        };
        await axios
          .put(
            `https://app.locusbase.no:4001/api/signs/${sign.id}`,
            json,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.GetSign();
            if (confirm("Lukk siden?")) {
              setTimeout(() => window.close(), 500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.GetSign();
      }
    },
    async deleteSign(sign) {
      if (confirm("Slette skiltet for altid?")) {
        const token = localStorage.token;
        await axios.delete(
          `https://app.locusbase.no:4001/api/signs/${sign.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await store.dispatch("fetchSigns");
        this.$router.push({
          path: `/adminpanel/`,
        });
      }
    },
  },
};
</script>

<style scoped>
.sign-display {
  height: 100px;
  width: 90px;
}
.sign-container {
  background-color: white;
  color: black;
  min-height: 92vh;
  padding-top: 60px;
}
.spacer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 15px 0;
}
.image {
  padding-bottom: 30px;
}

.google-maps-redirect {
  padding: 10px;
  border-radius: 5px;
}

.activate {
  background-color: rgb(57, 119, 57);
  color: white;
  border: none;
  border-radius: 5px;
  height: 35px;
}
.deactivate {
  background-color: #999999;
  color: white;
}
.delete-button {
  background-color: red;
  margin-top: 50px;
}
</style>