<template>
  <div class="create-user">
    <div class="creaute-user-container">
      <b-form class="create-input">
        <b-form-group
          class="label-space"
          id="input-group-1"
          label="E-mail/username"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            placeholder="marius@vink-kort-no"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          class="label-space"
          id="input-group-2"
          label="First name/middle name"
        >
          <b-form-input
            id="input-2"
            v-model="form.name"
            placeholder="Marius"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="label-space" id="input-group-3" label="Last name">
          <b-form-input
            id="input-3"
            v-model="form.lastname"
            placeholder="Håkestad"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="label-space" id="input-group-4" label="Telephone">
          <b-form-input
            id="input-4"
            v-model="form.phone"
            placeholder="999 99 999"
            type="tel"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="label-space" label="Roles:">
          <select name="" id="" v-model="form.roles">
            <option v-for="(r, index) in roles" :key="index" :value="r.id">
              {{ r.name }}
            </option>
          </select>
        </b-form-group>

        <b-form-group class="label-space" label="Tenant:">
          <select name="" id="" v-model="form.tenant">
            <option v-for="(t, index) in tenants" :key="index" :value="t.id">
              {{ t.name }}
            </option>
          </select>
        </b-form-group>

        <b-form-group class="label-space" label="Organization:">
          <select name="" id="" v-model="form.organization">
            <option
              v-for="(o, index) in organizations"
              :key="index"
              :value="o.id"
            >
              {{ o.name }}
            </option>
          </select>
        </b-form-group>

        <div class="btn-space">
          <button @click="createUser" class="btn-gradient btn-style">
            Create user
          </button>
          <button type="" class="btn-gradient btn-style">Send password</button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "CreateUserPage",
  data() {
    return {
      form: {
        email: "",
        name: "",
        lastname: "",
        phone: "",
        roles: "",
        tenant: "",
        organization: "",
      },
      show: true,
      roles: [],
      tenants: [],
      organizations: [],
    };
  },
  mounted() {
    this.fetchRoles();
    this.fetchTenants();
    this.fetchOrganizations();
  },
  methods: {
    async fetchRoles() {
      await store.dispatch("fetchRoles");
      this.roles = store.state.roles;
    },
    async fetchTenants() {
      await store.dispatch("fetchTenants");
      this.tenants = store.state.tenants;
    },
    async fetchOrganizations() {
      await store.dispatch("fetchOrganizations");
      this.organizations = store.state.organizations;
    },
    async createUser() {
      const token = localStorage.token;
      let json = {
        name: this.form.name + " " + this.form.lastname,
        nickname: this.form.name,
        email: this.form.email,
        phone_number: this.form.phone,
        roles: this.form.roles,
        tenant_id: this.form.tenant.id,
        organization_id: this.form.organization.id,
      };
      await axios
        .post(`https://app.locusbase.no:4001/api/users`, json, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.create-user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -220px;
}
input {
  width: 220%;
}
.label-space {
  padding-bottom: 10px;
}
.create-input {
  padding-bottom: 40px;
}
.btn-space {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  width: 100%;
}
.btn-style {
  padding: 5px 10px;
}
@media only screen and (max-width: 600px) {
  .create-user {
    display: flex;
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 100%;
  }
}
</style>