<template>
  <div
    class="link-button"
    @click="$router.push({ path: link, query: queries })"
  >
    <h3>{{ text }}</h3>
  </div>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    text: String,
    link: String,
    queries: Object,
  },
};
</script>

<style scoped>
div {
  margin: 0;
  padding: 0;
}

.link-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(242, 200, 75, 1) 0%,
    rgba(242, 96, 12, 1) 20%,
    rgba(242, 96, 12, 1) 100%
  );
} 


</style>