<template>
  <div class="ridelist">
    <div
      class="ride-container"
      v-for="(race, index) in races"
      :key="index"
      @click="chooseRide(race)"
    >
    <div class="org-logo">
      <img :src="logo[index]" alt="Bilde av kompani, bedrift" />
    </div>
      <div class="child">
        <div class="text">
          <p v-if="race.scheduled_at">
            <span>
              {{ new Date(race.scheduled_at).getDate() }}/{{
                new Date(race.scheduled_at).getMonth() + 1
              }}/{{ new Date(race.scheduled_at).getFullYear() }}
            </span>
          </p>
          <p>{{ race.name }}</p>
          <!-- <p>Lengde {{ race.distance }}km</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  data() {
    return {
      races: {},
      images: [
        {
          id: "9e6af333-68df-4328-afd2-83f234b0aeed",
          img: require("@/assets/images/logos/vink-logo.png"),
        },
        {
          id: "50837eca-3ef5-456f-8799-580c4a4a10fc",
          img: require("@/assets/images/logos/locus-base-white-logo.svg"),
        },
      ],
      logo: [],
    };
  },
  mounted() {
    this.fetchRaces();
  },
  methods: {
    chooseRide(race) {
      this.$router.push({
        path: `/adminpanel/valgt-ritt/${race.id}`,
        query: race,
      });
    },
    async fetchRaces() {
      await store.dispatch("fetchRaces");
      this.races = store.state.races
      this.getOrganization();
    },
    async getOrganization() {
      this.logo = [];
      for (let i = 0; i < this.races.length; i++) {
        var ele = this.images.find(
          (x) => x.id == this.races[i].organization_id
        );
        this.logo.push(ele.img);
        // this.logo = ele.img;
      }
    },
  },
};
</script>

<style scoped>
p {
  padding: 0;
  margin: 0;
}
img {
  height: 100px;
  width: 100px;
}
.ridelist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ride-container {
  width: 21%;
  padding: 30px 40px;
}

.flex-text {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .ridelist {
    display: flex;
    flex-direction: column;
  }
  .ride-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    padding: 30px;
  }
  .child {
    padding: 25px;
  }
}
</style>