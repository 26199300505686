<template>
  <div class="g-map-container">
    <MapNav />
    <div class="search-bar">
      <GmapAutocomplete
        @place_changed="setPlace"
        v-on:keyup.enter="setCenter"
        class="form-control"
      />
      <b-button @click="setCenter">Search</b-button>
    </div>
    <div class="g-map">
      <GmapMap
        :options="mapOptions"
        :center="center"
        :zoom="zoomLevel"
        map-type-id="terrain"
        class="g-map-control"
        @click="addPosition"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
        />

        <div v-for="(s, index) in signs" :key="'S' + index">
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == '302'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="stopSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == '302'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="stopSignActiveOptions"
          />
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == 'SafeCycling'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="safecyclingSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == 'SafeCycling'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="safecyclingSignActiveOptions"
          />
          <GmapMarker
            v-if="s.state == 1 && s.sign_type.name == '560'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="infoSignInactiveOptions"
          />
          <GmapMarker
            v-if="s.state == 2 && s.sign_type.name == '560'"
            class="placed-sign"
            :position="{ lat: s.location.latitude, lng: s.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate(s)"
            @dragend="updateSign($event, s)"
            :icon="infoSignActiveOptions"
          />
        </div>
        <div v-for="(w, index) in waypoints" :key="'W' + index">
          <GmapMarker
            class="placed-waypoint"
            :position="{ lat: w.location.latitude, lng: w.location.longitude }"
            :clickable="true"
            :draggable="true"
            @click="removeWaypoint(w)"
            @dragend="updateWaypoint($event, w)"
            :icon="waypointOptions"
          />
          <!-- <GmapLine
            class="placed-line"
            :position="{ lat: w.location.latitude, lng: w.location.longitude }"
          /> -->
        </div>

        <div v-for="(p, index) in path" :key="'P' + index">
          <DirectionsRenderer
            travelMode="WALKING"
            :origin="p.location.start"
            :destination="p.location.end"
            :draggable="true"
          />
        </div>

        <!-- <DirectionsRenderer
          travelMode="WALKING"
          :origin="path[0].location.start"
          :destination="path[path.length-1].location.end"
        /> -->

        <!-- <DirectionsRenderer
          travelMode="WALKING"
          :origin="startLocation"
          :destination="endLocation"
          :waypoints="path"
          :optimizedWaypoints="true"
        /> -->

        <gmap-info-window
          :options="{
            maxWidth: 300,
            pixelOffset: { width: 0, height: -35 },
          }"
          :position="infoWindow.location"
          :opened="infoWindow.open"
          @closeclick="infoWindow.open = false"
        >
          <div class="info-window-text">
            <h4>Sign info</h4>
            <div v-if="infoWindow.open">
              <h4>{{ infoWindow.sign.name }}</h4>
              <h4>lat: {{ infoWindow.sign.location.latitude.toFixed(5) }}</h4>
              <h4>lng: {{ infoWindow.sign.location.longitude.toFixed(5) }}</h4>
            </div>
            <button
              class="google-maps-redirect"
              @click="redirectWithGoogle(infoWindow.sign.location)"
            >
              Get google maps directions
            </button>
          </div>
        </gmap-info-window>
      </GmapMap>
    </div>
    <div class="btn-toggle">
      <img class="btn-style" @click="isHidden" :src="btnSVG" />
    </div>
    <div class="signs-container">
      <MapSignsChoose
        class=""
        v-if="showChooseSigns && isHidden"
        @select-current-sign="selectCurrentSign"
      />
    </div>
    <MapPosition v-if="showConfirmPosition" />
  </div>
</template>

<script>
import MapNav from "@/components/map/Navbar.vue";
import MapSignsChoose from "@/components/map/ChooseTrafficSign.vue";
import MapPosition from "@/components/map/ConfirmPosition.vue";
import DirectionsRenderer from "@/components/map/DirectionsRenderer";

import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "MapContainer",
  components: {
    MapNav,
    Map,
    MapSignsChoose,
    MapPosition,
    DirectionsRenderer,
  },
  // computed: {
  //   ...mapState(["signs"]),
  //   getSigns() {
  //     return this.signs;
  //   },
  // },
  // watch: {
  //   $data: {
  //     handler: function (newVal, oldVal) {
  //       console.log(oldVal, newVal);
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      roles: [],
      counterInterval: null,
      zoomLevel: 17,
      btnSVG: require("@/assets/images/icons/pluss-btn.svg"),
      showChooseSigns: false,
      showSigns: false,
      showConfirmPosition: false,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        sign: null,
      },
      center: { lat: 60, lng: 10 },
      signs: null,
      waypoints: [],
      path: [],
      currentPlace: null,
      markers: [],
      places: [],
      startLocation: null,
      endLocation: null,
      icons: {},
      stopSignInactiveOptions: {
        url: require("@/assets/images/signs/gray_302.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      stopSignActiveOptions: {
        url: require("@/assets/images/signs/red_302.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      safecyclingSignInactiveOptions: {
        url: require("@/assets/images/signs/safecycling.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      safecyclingSignActiveOptions: {
        url: require("@/assets/images/signs/safecycling.png"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      infoSignInactiveOptions: {
        url: require("@/assets/images/signs/clown_zone.jpg"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      infoSignActiveOptions: {
        url: require("@/assets/images/signs/clown_zone.jpg"),
        size: { width: 20, height: 20, f: "px", b: "px" },
        scaledSize: { width: 20, height: 20, f: "px", b: "px" },
      },
      waypointOptions: {
        url: require("@/assets/images/signs/waypoint.png"),
        size: { width: 80, height: 80, f: "px", b: "px" },
        scaledSize: { width: 80, height: 80, f: "px", b: "px" },
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: false,
      },
    };
  },
  destroyed() {
    clearInterval(this.counterInterval);
  },
  mounted() {
    this.getUserRoles();
    this.geolocate();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      },
      (error) => {
        console.log(error.message);
      }
    );
    this.callSignsByRace();
    this.callWaypointsByRace();
    this.callWaypointsByRace();
    this.counterInterval = window.setInterval(
      function () {
        this.callSignsByRace();
        // this.callWaypointsByRace();
      }.bind(this),
      10000
    );
  },
  methods: {
    async getUserRoles() {
      const token = localStorage.token;
      await axios
        .get(
          `https://app.locusbase.no:4001/api/users/${this.$auth.user.sub}/roles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          this.roles.push(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPlace(place) {
      // console.log(place);
      this.currentPlace = place;
    },
    setCenter() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        // this.markers.push({ position: marker });
        // this.places.push(this.currentPlace);
        this.center = { lat: 0, lng: 0 };
        this.center = marker;
        this.currentPlace = null;
        this.zoomLevel = 20;
        this.zoomLevel = 13;
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    isHidden() {
      this.showChooseSigns = !this.showChooseSigns;
    },
    redirectWithGoogle(newPosition) {
      navigator.geolocation.getCurrentPosition((position) => {
        let currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(currentPosition.lat.toFixed(7));
        console.log(currentPosition.lng.toFixed(7));
        console.log(newPosition.latitude.toFixed(7));
        console.log(newPosition.longitude.toFixed(7));
        window.location.href = `https://www.google.com/maps/dir/${currentPosition.lat.toFixed(
          7
        )},${currentPosition.lng.toFixed(7)}/${newPosition.latitude.toFixed(
          7
        )},${newPosition.longitude.toFixed(7)}`;
      });
    },
    addPosition(e) {
      console.log(e);
      if (this.selectedSign) {
        this.updateSign(e, this.selectedSign);
      } else {
        this.addWaypoint(e);
      }
    },
    selectCurrentSign(e) {
      this.selectedSign = e;
    },
    async callWaypointsByRace() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/waypoints`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.waypoints = data;
      // let test = JSON.parse(JSON.stringify(data));
      // console.log(test);
      this.path = [];
      for (let i = 0; i < this.waypoints.length - 1; i++) {
        let shortLocation1 = {
          lat: this.waypoints[i].location.latitude,
          lng: this.waypoints[i].location.longitude,
        };
        let shortLocation2 = {
          lat: this.waypoints[i + 1].location.latitude,
          lng: this.waypoints[i + 1].location.longitude,
        };
        this.path.push({
          location: { start: shortLocation1, end: shortLocation2 },
          stopover: true,
        });
        if (i == 0) {
          this.startLocation = shortLocation1;
        }
        if (i == this.waypoints.length - 2) {
          this.endLocation = shortLocation2;
          console.log(this.endLocation);
        }
      }
    },
    async addWaypoint(e) {
      if (confirm("Add waypoint?")) {
        const token = localStorage.token;
        let json = {
          race_id: this.$route.query.id,
          location: {
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
          },
        };
        await axios
          .post(
            `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/waypoints`,
            json,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });
      }
      this.callWaypointsByRace();
      this.callWaypointsByRace();
    },
    async updateWaypoint(e, waypoint) {
      if (confirm("Update waypoint?")) {
        const token = localStorage.token;
        let json = {
          location: {
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
          },
        };
        await axios
          .put(
            `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/waypoints/${waypoint.id}`,
            json,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });
      }
      this.callWaypointsByRace();
    },
    async removeWaypoint(waypoint) {
      if (confirm("Remove waypoint?")) {
        const token = localStorage.token;
        await axios
          .delete(
            `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/waypoints/${waypoint.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });
      }
      this.callWaypointsByRace();
    },
    async callSignsByRace() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/signs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.signs = data;
    },
    async updateSign(e, sign) {
      let admin = false;
      for (let i = 0; i < this.roles.length; i++) {
        if (
          this.roles[i].name == "admin" ||
          this.roles[i].name == "superadmin"
        ) {
          admin = true;
        }
      }
      if (admin) {
        if (confirm("Oppdatere skilt posisjon?")) {
          const token = localStorage.token;
          let json = {
            id: sign.id,
            race_day: sign.race_day,
            location: {
              latitude: e.latLng.lat(),
              longitude: e.latLng.lng(),
            },
            signtype_id: sign.signtype_id,
          };

          console.log(json);
          await axios
            .put(`https://app.locusbase.no:4001/api/signs/${sign.id}`, json, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              alert(err);
              console.log(err);
            });
        }
        this.callSignsByRace();
      }
    },
    openInfoWindowTemplate(sign) {
      this.zoomLevel = 0;
      this.zoomLevel = 15;
      this.center = {
        lat: sign.location.latitude,
        lng: sign.location.longitude,
      };
      this.infoWindow.location = {
        lat: sign.location.latitude,
        lng: sign.location.longitude,
      };
      this.infoWindow.sign = sign;
      this.infoWindow.open = true;
    },
  },
};
</script>

<style scoped>
.form-control {
  width: 30%;
}
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  z-index: 2;
}
.superb {
  position: absolute;
  z-index: 5000;
}
.g-map-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: 30px;
  height: 100%;
  width: 100%;
}

.g-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.g-map-control {
  height: 100%;
  width: 100%;
}
.info-window-text {
  color: #000;
  /* background-color: blue; */
}
.google-maps-redirect {
  padding: 10px;
  border-radius: 5px;
}

/* @media only screen and (max-width: 600px) {
  .map-sign-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
} */

.btn-toggle {
  width: 100%;
  z-index: 2;
  bottom: 0;
}
.btn-style {
  position: fixed;
  width: 80px;
  left: 15px;
  bottom: 25px;
}

.placed-waypoint {
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .btn-style {
    width: 40px;
    right: 25px;
  }
  .form-control {
    width: 60%;
  }
}
</style>