<template>
  <div class="border-style">
    <div class="chose-traffic-signs">
      <div class="box">
        <div class="flex-row"></div>
        <div class="traffic-sign-wrapper">
          <div class="flex-row">
            <div
              class="traffic-sign-container"
              v-for="(sign, index) in sortedSigns"
              :key="index"
            >
              <div
                class="flex-column list-sign-container"
                draggable="true"
                @click="selectCurrentSign($event, sign)"
              >
                <img
                  class="list-sign"
                  src="@/assets/images/signs/red_302.png"
                  alt="Bilde av skilt"
                  v-if="
                    sign.signtype_id == '85dff9b7-2851-488e-8dc1-469c090bb25c'
                  "
                />
                <img
                  class="list-sign"
                  src="@/assets/images/signs/safecycling.png"
                  alt="Bilde av skilt"
                  v-if="
                    sign.signtype_id == '7ee1fe29-3e68-4640-8788-aedac3665955'
                  "
                />
                <img
                  class="list-sign"
                  src="@/assets/images/signs/clown_zone.jpg"
                  alt="Bilde av skilt"
                  v-if="
                    sign.signtype_id == '920794a0-f089-4d5e-a5b0-6da9aa98b493'
                  "
                />
              </div>
              <div class="flex-column">
                <p class="sign-name">{{ sign.name }}</p>
              </div>
            </div>
          </div>
          <!-- <div class="btn-remove-container">
            <button class="btn-remove" @click="removeSignsFromRace()">
              Fjern alle skilt fra ritt
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChooseTrafficSignToMap",
  data() {
    return {
      signs: [],
      sortedSigns: [],
    };
  },
  updated() {
    this.callSignsByRace();
  },
  mounted() {
    this.callSignsByRace();
  },
  methods: {
    async callSignsByRace() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/signs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.signs = data;
      this.sortSigns();
    },
    selectCurrentSign(e, sign) {
      if (e.target.style.backgroundColor == "rgb(255, 255, 0)") {
        e.target.style.backgroundColor = "#0a1720";
        this.$emit("select-current-sign", null);
      } else {
        let imgs = document.getElementsByClassName("list-sign");
        for (let i = 0; i < imgs.length; i++) {
          imgs[i].style.backgroundColor = "#0a1720";
        }
        e.target.style.backgroundColor = "#ffff00";
        this.$emit("select-current-sign", sign);
      }
    },
    async removeSignsFromRace() {
      if (confirm("Fjerne alle skilt fra løpet?")) {
        if (confirm("Er du helt sikker?")) {
          for (let s = 0; s < this.signs.length; s++) {
            const token = localStorage.token;
            await axios.delete(
              `https://app.locusbase.no:4001/api/races/${this.$route.query.id}/signs/${this.signs[s].id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
      }
      this.callSignsByRace();
    },
    sortSigns() {
      this.sortedSigns = this.signs;
      this.sortedSigns = this.sortedSigns.sort((a, b) => {
        let fa = ("00" + a.name).slice(-7).toLowerCase(),
          fb = ("00" + b.name).slice(-7).toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped>
button {
  border-radius: 5px;
  margin: 10px 0 0 10px;
  padding: 14;
}

.border-style {
  position: absolute;
  top: 15%;
  left: 4%;
  margin: 10px 1%;
  padding: 5px;
  border-radius: 20px;
  background-color: #0a1720;
  text-align: center;
  background-image: url("@/assets/images/styles/border-dashed.svg");
}
.chose-traffic-signs {
  background-color: #0a1720;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.box {
  padding: 15px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.traffic-sign-container {
  padding: 0 10px;
}

.traffic-sign-wrapper {
  padding: 15px;
  height: 310px;
  width: 330px;
  overflow-y: auto;
}
.traffic-sign-wrapper::-webkit-scrollbar {
  width: 20px;
}
.traffic-sign-wrapper::-webkit-scrollbar-track {
  background: #696969;
  border-radius: 100px;
}
.traffic-sign-wrapper::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #f2561f, #ebc13a);
  border-radius: 100px;
}

.category-sign {
  height: 100px;
  width: 100px;
}
.list-sign-container {
  border-radius: 100%;
}

.list-sign {
  padding: 5px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.sign-name {
  max-width: 50px;
}

::-webkit-scrollbar {
  height: 0px;
  width: 8px;
}

.btn-remove {
  height: 40px;
  background-color: #ca2020;
  color: white;
}
@media only screen and (max-width: 600px) {
  .border-style {
    position: fixed;
    bottom: 0;
    width: 96%;
    top: initial;
  }
  .traffic-sign-container {
    display: flex;
    justify-content: space-evenly;
    padding: 0 5px;
    margin: 0px;
  }

  .chose-traffic-signs {
    padding: 0 10px 0 10px;
  }
  .traffic-sign-wrapper {
    height: 110px;
  }
}
</style>