import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home.vue"
import Map from "@/views/Map.vue"
import Test from "@/views/Test.vue"

// Administration
import Adminpanel from "@/views/Management/Adminpanel.vue"
import SelectedRide from "@/views/Management/SelectedRide.vue"
import SelectedSign from "@/views/Management/signs/SelectedSign.vue"

// Auth0 test
import ExternalApi from "@/views/ExternalApi.vue";

// Auth0 Guard
import { authGuard } from '@/auth/authGuard';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/externalapi',
    name: 'externalapi',
    component: ExternalApi,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/adminpanel',
    name: 'Adminpanel',
    component: Adminpanel,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/adminpanel/valgt-ritt/:id',
    name: 'SelectedRide',
    component: SelectedRide,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
  {
    path: '/skilt/:id',
    name: 'SelectedSign',
    component: SelectedSign,
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    }
  },
]

// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
// }


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
