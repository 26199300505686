<template>
  <div class="hamburger-menu">
    <div class="hamburger-container">
      <button class="lb" @click="goTo('/')">Home</button>
      <button class="lb" @click="goTo('/adminpanel')">Admin panel</button>
      <button class="lb" @click="logout()">Logout</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
  data() {
    return {};
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path });
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style scoped>
.hamburger-menu {
  top: 90px;
  right: 0px;
  width: 100%;
  /* border: 3px solid #fff; */
  /* border-radius: 0 0 15% 15%; */
  /* box-shadow: 0px 2px 4px 0px #fff; */
  background-color: #0a1720;
  /* padding: 15px 60px; */
}

.hamburger-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
}

.lb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 60px;
  width: 300px;
  border-radius: 5px;
  border: none;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(242, 200, 75, 1) 0%,
    rgba(242, 96, 12, 1) 20%,
    rgba(242, 96, 12, 1) 100%
  );
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .hamburger-container {
    padding: 0px;
  }
}
</style>