<template>
  <div class="sign-collection">
    <div v-for="s in signs" :key="s.id">
      <div
        class="sign-container"
        v-if="s.race_day == 1"
        @click="chooseSign(s)"
        formTarget="_blank"
      >
        <img
          v-if="s.signtype_id == '85dff9b7-2851-488e-8dc1-469c090bb25c'"
          style="filter: grayscale(1)"
          class="sign-display"
          src="@/assets/images/signs/red_302.png"
          :alt="s.name"
        />
        <img
          v-if="s.signtype_id == '7ee1fe29-3e68-4640-8788-aedac3665955'"
          style="filter: grayscale(1)"
          class="safecycling"
          src="@/assets/images/signs/safecycling.png"
          :alt="s.name"
        />
        <img
          v-if="s.signtype_id == '920794a0-f089-4d5e-a5b0-6da9aa98b493'"
          style="filter: grayscale(1)"
          class="safecycling"
          src="@/assets/images/signs/clown_zone.jpg"
          :alt="s.name"
        />
        <!-- <img
        v-if="s.state == 1"
        style="filter: grayscale(1)"
        class="sign-display "
        src="@/assets/images/signs/red_302.png"
        :alt="s.name"
      />
      <img
        v-else-if="s.state == 2"
        class="sign-display"
        src="@/assets/images/signs/red_302.png"
        :alt="s.name"
      /> -->
        <!-- <p>{{ s.signtype.name }}</p> -->
        <p>Skiltnummer {{ s.sequence_number }}</p>
        <p>{{ s.name }}</p>
      </div>
    </div>
    <!-- <table>
      <tr v-for="(s, index) in signs" :key="index">
        <td v-if="s.race_day == 1">{{ s.name }}</td>
        <td v-if="s.race_day == 1">https://app.locusbase.no/skilt/{{ s.qr_code }}</td>
      </tr>
    </table> -->
  </div>
</template>

<script>
export default {
  name: "SignCollection",
  data() {
    return {
      sortedSigns: [],
    };
  },
  props: {
    signs: Array,
  },
  mounted() {
    this.sortSigns();
    console.log(this.signs);
  },
  methods: {
    sortSigns() {
      this.sortedSigns = this.signs;
      this.sortedSigns = this.sortedSigns.sort((a, b) => {
        let fa = ("00" + a.name).slice(-7).toLowerCase(),
          fb = ("00" + b.name).slice(-7).toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
    chooseSign(sign) {
      // this.$router.push({
      //   path: `/skilt/${sign.qr_code}`,
      // });
      let routeData = this.$router.resolve({
        path: `/skilt/${sign.qr_code}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
.sign-collection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.sign-container {
  margin: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.safecycling {
  height: 100px;
  width: 90px;
}

.sign-display {
  height: 100px;
  width: 100px;
}

p {
  margin: 0;
}

table,
tr,
td {
  border: 1px solid black;
}

td {
  padding: 0 5px;
}

@media only screen and (max-width: 600px) {
  .sign-collection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .sign-container {
    padding: 10px;
    width: 50%;
  }
  .sign-display {
    height: 60px;
    width: 60px;
  }
}
</style>