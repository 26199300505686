<template>
  <div class="userlist-container">
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th class="mobile">Role</th>
            <th>Race</th>
            <th class="mobile">Telephone</th>
            <th class="mobile">E-mail</th>
            <!-- <th class="mobile">ID</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="u in users" :key="u.id">
            <td>{{ u.name }}</td>
            <td>---</td>
            <!-- <td>{{ u.name }}</td> -->
            <td class="mobile">{{ u.email }}</td>
            <!-- her -->
            <td class="mobile">{{ u.phone_number }}</td>
            <td class="mobile">{{ u.email }}</td>
            <!-- <td class="mobile">{{ u.id }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserList",
  props: {
    users: Array,
  },
  mounted() {
    // for (let i = 0; i < this.users.length; i++) {
    //   console.log(this.users[i].email, this.users[i].id);      
    // }
    this.callUsers();
    // console.log(this.$route);
  },
  methods: {
    async callUsers() {
      const token = localStorage.token;
      // Emil
      let user = "auth0|6253f1e772b70500693ad5ff";
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/users/${user}/roles`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.signs = data;
    },
  },
};
</script>

<style scoped>
.userlist-container {
  height: 72.2vh;
}
th,
td {
  padding: 15px 25px 15px 25px;
}
tr:not(:last-of-type) {
  border-bottom: 2px solid #dddd;
}

@media only screen and (max-width: 600px) {
  .mobile {
    display: none;
  }
  th,
  td {
    padding-left: 0;
  }
}
</style>