<template>
  <div class="test">
    <SignPopupInfo />
    <SignPositionConfirm />
  </div>
</template>

<script>
import SignPopupInfo from "@/components/map/SignPopupInfo.vue";
import SignPositionConfirm from "@/components/map/SignPositionConfirm.vue";

export default {
  components: {
    SignPopupInfo,
    SignPositionConfirm,
  },
};
</script>

<style scoped>
.test {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
