<template>
  <div class="nav-category">
    <div class="nav-row">
      <div
        class="main-category-wrapper"
        v-for="(category, index) in categories"
        :key="index"
      >
        <h4 class="main-category" @click="changeCategory(index, $event)">
          {{ category.mainCategory }}
        </h4>
      </div>
    </div>
    <NavSubCategory
      :subCategories="categories[selectedCategory].subCategories"
      :ResetSubCategory="0"
    />
  </div>
</template>

<script>
import NavSubCategory from "@/components/administrativ/SubCategory.vue";
export default {
  name: "navCategory",
  components: {
    NavSubCategory,
  },
  props: {
    categories: Array,
  },
  data() {
    return {
      selectedCategory: 0,
    };
  },
  methods: {
    changeCategory(index, e) {
      let headers = document.getElementsByTagName("h4");
      let subHeaders = document.getElementsByTagName("h5");
      for (let i = 0; i < headers.length; i++) {
        headers[i].style.color = "#000000";
      }
      for (let y = 0; y < subHeaders.length; y++) {
        subHeaders[y].style.color = "#000000";
      }
      e.target.style.color = "#ffbb00";
      this.selectedCategory = index;
    },
    changeUrl(text) {
      window.location.href = `#heading-${this.headingHash(text)}`;
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
};
</script>

<style scoped>
.nav-category {
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  align-items: flex-start;
  text-align: left;
  width: 60%;
}

.nav-row {
  display: flex;
  flex-direction: row;
}
.m-c {
  padding-right: 10px;
}
.main-category-wrapper:first-child {
  color: #ffbb00;
}

.main-category {
  margin: 0 60px 15px 0;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .nav-category {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>