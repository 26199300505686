<template>
  <div class="create-race">
    <div>
      <b-form-group class="pb-3" id="input-group-1" label="Title of race">
        <b-form-input v-model="form.title" placeholder="" required>
        </b-form-input>
      </b-form-group>
      <b-form-group class="pb-3" id="input-group-1" label="Ritt dag">
        <b-form-input
          type="number"
          min="0"
          v-model="form.day"
          placeholder=""
          required
        >
        </b-form-input>
      </b-form-group>

      <b-form-group class="pb-3" id="input-group-1" label="Date">
        <b-form-datepicker v-model="form.date" class="mb-2"></b-form-datepicker>
        <!-- </b-form-input> -->
      </b-form-group>

      <button @click="createRace()" class="btn-gradient btn-size">
        Create race
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/index.js";
export default {
  data() {
    return {
      preSigns: [],
      signs: [],
      form: {
        title: "",
        day: null,
        date: null,
      },
    };
  },
  methods: {
    async createRace() {
      let user = await this.callUser();
      const token = localStorage.token;
      let json = {
        name: this.form.title,
        race_day: this.form.day,
        notes: "",
        scheduled_at: `${new Date(this.form.date).toUTCString()}`,
        created_by: this.$auth.user.name,
        organization_id: user.organization_id,
        tenant_id: user.tenant_id,
      };
      await axios
        .post(`https://app.locusbase.no:4001/api/races`, json, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.addSignsToRace(res.data);
          this.$emit("change-category", 0);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async callUser() {
      const token = localStorage.token;
      const { data } = await axios.get(
        `https://app.locusbase.no:4001/api/users/${this.$auth.user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    async addSignsToRace(raceData) {
      const token = localStorage.token;
      await this.fetchSigns(raceData);
      for (let sign = 0; sign < this.signs.length; sign++) {
        let json = {
          id: this.signs[sign].id,
          race_day: raceData.race_day,
          notes: "",
          location: {
            latitude: 0,
            longitude: 0,
          },
          signtype_id: this.signs[sign].signtype_id,
          sign_type: {
            description: this.signs[sign].sign_type.description,
            id: this.signs[sign].sign_type.id,
            name: this.signs[sign].sign_type.name,
            reusable: this.signs[sign].sign_type.reuasable,
          },
        };
        await axios
          .post(
            `https://app.locusbase.no:4001/api/races/${raceData.id}/signs`,
            json,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async fetchSigns(raceData) {
      await store.dispatch("fetchSigns");
      this.preSigns = store.state.signs;
      this.signs = [];
      for (let s = 0; s < this.preSigns.length; s++) {
        if (raceData.race_day == this.preSigns[s].race_day) {
          this.signs.push(this.preSigns[s]);
          this.signs.sort((a, b) => {
            a.name - b.name;
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.create-race {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 0 10%;
}
.form-control {
  width: 100%;
}

.btn-size {
  width: 200px;
  height: 35px;
}

@media only screen and (max-width: 600px) {
  .create-race {
    padding-left: 65px;
  }
}
</style>