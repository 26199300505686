<template>
  <div>
    <b-navbar toggleable>
      <!-- Left Aligned -->
      <b-navbar-brand>
        <img
          class="logo-size"
          src="../assets/images/logos/locus-base-white-logo.svg"
          alt="logo"
        />
      </b-navbar-brand>
  
      <!-- Right Aligned -->
      <img
        class="hamburger-size"
        src="@/assets/images/icons/hamburger-gradient.svg"
        alt="menu"
        @click="flipBurger()"
      />
    </b-navbar>
    <HamburgerMenu v-if="this.burger" @close-burger="flipBurger()"/>
  </div>
</template>

<script>
import HamburgerMenu from "@/components/HamburgerMenu.vue";
export default {
  name: "Navbar",
  data() {
    return {
      burger: false,
    };
  },
  components: {
    HamburgerMenu,
  },
  methods: {
    flipBurger() {
      this.burger = !this.burger;
    },
  },
};
</script>

<style scoped>
.logo-size {
  width: 70%;
  padding: 10px 15px;
}

.hamburger-size {
  width: 4%;
  padding: 10px 15px;
}

@media only screen and (max-width: 600px) {
  .logo-size {
    width: 56%;
    padding: 10px 15px;
  }

  .hamburger-size {
    width: 16%;
    padding: 10px 15px;
  }
}
</style>
